import React from 'react';

//

const Module = props => {
  const {children} = props;
  return (
    <div className="flex min-h-[16rem] justify-center px-4 py-2">
      <div className="rounded-box flex max-w-6xl flex-grow flex-wrap overflow-hidden bg-base-100 shadow-xl">{children}</div>
    </div>
  );
};

export default Module;
