import React from 'react';

//

const Head = ({title, filled, children}) => {
  return (
    <div className={`relative z-10 flex w-full justify-center p-4 ${filled ? 'bg-primary' : ''}`}>
      <div className="flex max-w-6xl flex-grow flex-nowrap items-center justify-between">
        <a href="./">
          <img src="/logo.png" alt={title} className="block max-h-8" />
        </a>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Head;
