import React from 'react';
import {List, X} from 'lucide-react';

import {
  checkEmpty,
  sum,
  percentage,
  getQuarterIdFromQuarter,
  getRootTransactions,
  getElementsFromQuarterId,
  getElementsByKey,
  getOrderFromClientByGroup,
  sortElementsBySpecificOrder,
} from './helpers';
import Module from './Module';
import SectionDetails from './SectionDetails';

//

class ModuleGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.groups = [
      {name: 'Catégorie', key: 'parent_category'},
      {name: 'Type', key: 'type'},
      {name: 'Sous-catégorie', key: 'sub_category'},
    ];
    this.state = {group: 'parent_category', item: 0, details: false, query: ''};
    this.changeGroup = this.changeGroup.bind(this);
    this.clickItem = this.clickItem.bind(this);
    this.details = this.details.bind(this);
    this.search = this.search.bind(this);
  }

  // componentWillReceiveProps(nextProps)
  // {
  //   // reset state before update
  //   this.setState({ item: 0, details: false })
  // }

  changeGroup(e) {
    // reset selected item
    this.setState({item: 0});
    this.setState({group: e.target.value});
  }

  clickItem(i) {
    if (i !== this.state.item) {
      this.setState({item: i});
    }
  }

  details() {
    this.setState({details: !this.state.details, query: ''});
  }

  search(event) {
    const query = event.target.value.toLowerCase();
    this.setState({query});
  }

  render() {
    const {client, transactions, quarterCurrent, quarterTarget} = this.props;

    let jsx;

    // get transactions
    // filter non root transactions (that have an ancestry)
    const transactionsCurrent = getRootTransactions(getElementsFromQuarterId(transactions, getQuarterIdFromQuarter(quarterCurrent)));
    const transactionsCurrentEmpty = typeof transactionsCurrent === 'undefined' ? true : checkEmpty(transactionsCurrent.transactions);
    const total = typeof transactionsCurrent !== 'undefined' ? sum(transactionsCurrent.transactions) : 0;

    // render component only if current quarter has categories/transactions associate to it
    if (transactionsCurrentEmpty) return null;

    // TODO: ensure that groups are not empty (possible if type/category/subcategory fields are empty)
    let transactionsCurrentGroups = getElementsByKey(transactionsCurrent.transactions, this.state.group);

    // sort groups by custom order
    const order = getOrderFromClientByGroup(client, this.state.group);
    transactionsCurrentGroups = sortElementsBySpecificOrder(transactionsCurrentGroups, order);

    const transactionsCurrentGroup = transactionsCurrentGroups[this.state.item];
    const transactionsTarget = quarterTarget && getRootTransactions(getElementsFromQuarterId(transactions, getQuarterIdFromQuarter(quarterTarget)));

    let searchfield;
    if (this.state.details) {
      searchfield = <input onChange={this.search} type="text" placeholder="Recherche..." className="input input-bordered input-sm m-2" />;
    }

    let details;
    if (this.state.details) {
      details = (
        <SectionDetails
          client={client}
          quarterCurrent={quarterCurrent}
          quarterTarget={quarterTarget}
          transactionsCurrentGroup={transactionsCurrentGroup}
          transactionsTarget={transactionsTarget}
          query={this.state.query}
        />
      );
    }

    //

    return (
      <Module>
        <div className="flex flex-none basis-full flex-wrap overflow-hidden md:basis-2/3">
          <div className="basis-full border-r py-4 min-[480px]:basis-1/2">
            <h3 className="mb-4 h-8 px-4 font-bold leading-8 text-primary">Vue d'ensemble</h3>
            <select value={this.state.group} onChange={e => this.changeGroup(e)} className="select select-bordered select-xs mx-4 my-2">
              {this.groups.map(group => (
                <option key={group.key} value={group.key}>
                  {group.name}
                </option>
              ))}
            </select>
            <ul className="menu menu-horizontal menu-sm px-4 min-[480px]:menu-vertical">
              {transactionsCurrentGroups.map((group, i) => (
                <li key={i} className={`${i === this.state.item ? 'disabled' : ''}`}>
                  <button onClick={() => this.clickItem(i)} className={`${i === this.state.item ? 'bg-primary text-neutral' : ''}`}>
                    {group.key}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex basis-full items-center justify-center min-[480px]:basis-1/2">
            <div className="stat w-auto pr-16 max-[480px]:pb-16">
              <div className="stat-title text-slate-400">Total</div>
              <div className="stat-value text-2xl">{sum(transactionsCurrentGroup.elements).toLocaleString('de-CH')}</div>
              <div className="stat-desc text-primary">
                {percentage(sum(transactionsCurrentGroup.elements), total)}
                &#8239;%
              </div>
            </div>
          </div>
        </div>

        <div className="flex-none basis-full overflow-hidden bg-neutral md:basis-1/3">
          <div className="flex h-full flex-col items-center justify-center p-4">
            <label className="text-primary">Détails</label>
            <div className="flex">
              {searchfield}
              <button title="Afficher/masquer les détails" onClick={() => this.details()} className="btn btn-neutral btn-sm my-2 text-primary focus:bg-slate-300">
                {this.state.details ? <X /> : <List />}
              </button>
            </div>
          </div>
        </div>

        {details}
      </Module>
    );
  }
}

export default ModuleGeneral;
