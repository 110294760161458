import React from 'react';

import {sum, getQuarterIdFromQuarter, getQuarterNameFromQuarter, getRootTransactions, getElementsFromQuarterId} from './helpers';
import Variation from './Variation';

//

const SectionFortune = ({currency, transactions, quarterCurrent, quarterTarget}) => {
  const transactionsCurrent = getRootTransactions(getElementsFromQuarterId(transactions, getQuarterIdFromQuarter(quarterCurrent)));

  const totalCurrent = typeof transactionsCurrent !== 'undefined' ? sum(transactionsCurrent.transactions) : 0;

  let variation;
  if (quarterTarget.quarter) {
    const transactionsTarget = getRootTransactions(getElementsFromQuarterId(transactions, getQuarterIdFromQuarter(quarterTarget)));

    const totalTarget = typeof transactionsTarget !== 'undefined' ? sum(transactionsTarget.transactions) : 0;

    variation = (
      <>
        <span className="mr-1">Évolution </span>
        <span className="mr-2 overflow-hidden text-ellipsis whitespace-nowrap">
          {getQuarterNameFromQuarter(quarterCurrent)}
          &#8239;-&#8239;
          {getQuarterNameFromQuarter(quarterTarget)}
        </span>
        <Variation value={totalCurrent - totalTarget} />
      </>
    );
  }

  return (
    <div className="flex h-full flex-col justify-center overflow-hidden py-8">
      <div className="stat pt-8">
        <div className="stat-title text-slate-400">Fortune</div>
        <div className="stat-value text-2xl">
          {totalCurrent.toLocaleString('de-CH')} {currency}
        </div>
        <div title="Évolution patrimoine" className="stat-desc flex h-8">
          {variation}
        </div>
      </div>
    </div>
  );
};

export default SectionFortune;
