import React from 'react';
import {useRef, useMemo} from 'react';
import {Canvas, extend, useThree, useFrame} from 'react-three-fiber';
import * as meshline from 'threejs-meshline';
import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

extend(meshline);
extend({OrbitControls});

import {ReactComponent as Dash} from './dash.svg';

//

const blue = '#163B82';
const autoRotateSpeed = 1.5;

//

const Landing = () => {
  return (
    <div id="landing">
      <div className="relative" style={{height: '24em'}}>
        <div className="grad1 shadow-bottom top-0 block">
          <span style={{bottom: '0', right: '0'}}></span>
          <span style={{bottom: '0', right: '30vw'}}></span>
          <span style={{bottom: '0', right: '60vw'}}></span>
          <span style={{bottom: '6em', right: '-15vw'}}></span>
          <span style={{bottom: '6em', right: '15vw'}}></span>
          <span style={{bottom: '12em', right: '0'}}></span>
        </div>
        <div className="bg-floating container relative">
          <div className="img">
            <Dash className="shadow-top" />
          </div>
          <div className="txt max-width-60">
            <h1>Une nouvelle solution pour la gestion de vos actifs</h1>
            <p>
              FP System est une solution unique, développée par nos équipes de spécialistes pour maîtriser, analyser et suivre l'évolution de l'entier de votre patrimoine, sur une
              interface moderne et personnalisée.
            </p>
            <div className="tags">
              <span style={{backgroundColor: blue}} className="button no-select">
                Swiss Made
              </span>
              <span style={{backgroundColor: blue}} className="button no-select">
                Software
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="blueprint">
        <div className="padding-top">
          <div className="reverse bg-floating container">
            <div className="item">
              <div className="txt">
                <h1>Personnalisation</h1>
                <p>
                  L'outil a été pensé pour faire face aux spécificités de chaque situation, en offrant une interface simple à utiliser, sur-mesure et modulable afin de coller au
                  mieux à vos besoins.
                </p>
                <div className="tags">
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Comptes
                  </span>
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Immobilier
                  </span>
                  <span style={{backgroundColor: blue}} className="button no-select">
                    LPP
                  </span>
                </div>
              </div>
            </div>
            <div className="item">
              <Form r={-autoRotateSpeed}>
                <Lines1 />
              </Form>
            </div>
          </div>
        </div>
        <div>
          <div className="bg-floating container">
            <div className="item">
              <div className="txt">
                <h1>Historique</h1>
                <p>
                  Élément phare de FP System, la conservation de l'historique. Notre outil est le gardien de vos données patrimoniales et de son évolution à travers les années.
                </p>
                <div className="tags">
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Prêts
                  </span>
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Placements
                  </span>
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Succession
                  </span>
                </div>
              </div>
            </div>
            <div className="item">
              <Form r={autoRotateSpeed}>
                <Lines2 />
              </Form>
            </div>
          </div>
        </div>

        <div className="padding-bottom">
          <div className="reverse bg-floating container">
            <div className="item">
              <div className="txt">
                <h1>Analyse</h1>
                <p>Comparer, détailler, regrouper, isoler, sont quelques-unes des nombreuses fonctionnalités offertes par FP System dans l'analyse de votre patrimoine.</p>
                <div className="tags">
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Risques
                  </span>
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Rendements
                  </span>
                  <span style={{backgroundColor: blue}} className="button no-select">
                    Dettes
                  </span>
                </div>
              </div>
            </div>
            <div className="item">
              <Form r={-autoRotateSpeed}>
                <Lines3 />
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="relative" style={{height: '37em'}}>
        <div className="grad2 shadow-bottom bottom-0 block">
          <span style={{top: '0', left: '0'}}></span>
          <span style={{top: '0', left: '30vw'}}></span>
          <span style={{top: '0', left: '60vw'}}></span>
          <span style={{top: '6em', left: '-15vw'}}></span>
          <span style={{top: '6em', left: '15vw'}}></span>
          <span style={{top: '12em', left: '0'}}></span>
        </div>
        <div className="reverse bg-floating container relative">
          <div className="txt max-width-60 txt-right">
            <h1>Contact</h1>
            <p>Nous attachons une grande importance au contact humain. Nous serions ravis de pouvoir vous rencontrer et vous faire une démonstration de notre outil.</p>
            <div className="contact">
              <a href={`mailto:${process.env.REACT_APP_LANDING_MAIL}`} className="button">
                Contactez-nous
              </a>
            </div>
          </div>
        </div>
        <div className="copyright">
          <span>Schoeb Frôté SA ©</span>
        </div>
      </div>
    </div>
  );
};

export default Landing;

//

const Form = props => {
  return (
    <Canvas
      orthographic
      pixelRatio={window.devicePixelRatio}
      camera={{
        far: 5000,
        position: [0, 0, 1000],
        up: [0, 0, 1],
        zoom: 2,
      }}
    >
      <Controls r={props.r} />
      {props.children}
    </Canvas>
  );
};

const Controls = props => {
  const controls = useRef();
  const {camera, gl} = useThree();

  useFrame(() => controls.current.update());

  return (
    <orbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      enableZoom={false}
      enablePan={false}
      enableDamping
      dampingFactor={0.05}
      rotateSpeed={0.5}
      autoRotate={true}
      autoRotateSpeed={props.r * 0.5}
      minPolarAngle={(Math.PI / 10) * 3}
      maxPolarAngle={(Math.PI / 10) * 3}
    />
  );
};

const line = (p, l, s1 = [15, 15, 20], s2 = [10, 10, 20], c = 'white') => ({
  points: p.map(pt => new THREE.Vector3(...pt).multiply(new THREE.Vector3(...s1))),
  position: new THREE.Vector3(...l).multiply(new THREE.Vector3(...s2)),
  color: c,
});
const crossFat = l => [
  line(
    [
      [-1, -1, 0],
      [1, 1, 0],
    ],
    l
  ),
  line(
    [
      [1, -1, 0],
      [-1, 1, 0],
    ],
    l
  ),
];
const squareFat = l => [
  line(
    [
      [1, -1, 0],
      [1, 1, 0],
    ],
    l
  ),
  line(
    [
      [1, 1, 0],
      [-1, 1, 0],
    ],
    l
  ),
  line(
    [
      [-1, 1, 0],
      [-1, -1, 0],
    ],
    l
  ),
  line(
    [
      [-1, -1, 0],
      [1, -1, 0],
    ],
    l
  ),
];
const square = [
  [1, -1, 0],
  [1, 1, 0],
  [1, 1, 0],
  [-1, 1, 0],
  [-1, 1, 0],
  [-1, -1, 0],
  [-1, -1, 0],
  [1, -1, 0],
];
const cross = [
  [-1, -1, 0],
  [1, 1, 0],
  [1, -1, 0],
  [-1, 1, 0],
];
const triangle = [
  [0, 0, -1],
  [-1, 0, 1],
  [-1, 0, 1],
  [1, 0, 1],
  [1, 0, 1],
  [0, 0, -1],
];
const random = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
};

const Lines1 = () => {
  const r1 = random(1, -2);
  const r2 = random(1, -2);
  const fat = useMemo(() => [...squareFat([-4, -4, 2]), ...crossFat([4, 0, r1]), ...squareFat([0, 4, 0])], [r1]);
  const tin = useMemo(() => [line(cross, [-4, -4, r2]), line(square, [4, 0, 1]), line(square, [0, 4, 1]), line(square, [0, 0, -1], [70, 70, 10])], [r2]);

  return (
    <>
      {fat.map((props, index) => (
        <Fatline key={`f${index}`} {...props} />
      ))}
      {tin.map((props, index) => (
        <Segments key={`f${index}`} {...props} />
      ))}
    </>
  );
};
const Lines2 = () => {
  const segments = (n, min, max) =>
    [...Array(n)].map(() => {
      const l = random(2, 4);
      const x = random(-8, 8);
      const y = random(-4, 4);
      const z = random(min, max);
      return line(
        [
          [-l / 2, 0, 0],
          [l / 2, 0, 0],
        ],
        [x, y, z]
      );
    }, []);
  const triangles = n =>
    [...Array(n)].map(() => {
      const x = random(-8, 8);
      const y = random(-4, 4);
      const z = random(-2, 2);
      return line(triangle, [x, y + 0.5, z], [5, 15, 5]);
    }, []);

  const fat = useMemo(() => segments(6, -1, 1), []);
  const tin = useMemo(() => [...segments(6, -2, 2), ...triangles(4)], []);

  return (
    <>
      {fat.map((props, index) => (
        <Fatline key={`f${index}`} {...props} />
      ))}
      {tin.map((props, index) => (
        <Segments key={`f${index}`} {...props} />
      ))}
    </>
  );
};
const Lines3 = () => {
  const positions = n =>
    [...Array(n)].map(() => {
      const l = random(2, 8);
      const x = random(-6, 6);
      const y = random(-6, 6);
      const z = random(-2, 2);
      return [[x, y, z], l];
    });
  const p1 = positions(4);
  const p2 = positions(4);

  const fat = useMemo(
    () => [
      ...p2.map(p =>
        line(
          [
            [0, 0, -p[1] / 2],
            [0, 0, p[1] / 2],
          ],
          p[0]
        )
      ),
      ...p2.map(p => line(square, p[0], [5, 5, 20])),
    ],
    [p2]
  );
  const tin = useMemo(
    () => [
      ...p1.map(p =>
        line(
          [
            [0, 0, -p[1] / 2],
            [0, 0, p[1] / 2],
          ],
          p[0]
        )
      ),
      ...p1.map(p => line(square, p[0])),
    ],
    [p1]
  );

  return (
    <>
      {fat.map((props, index) => (
        <Fatline key={`f${index}`} {...props} />
      ))}
      {tin.map((props, index) => (
        <Segments key={`f${index}`} {...props} />
      ))}
    </>
  );
};

const Fatline = props => {
  return (
    <mesh position={props.position}>
      <meshLine attach="geometry" vertices={props.points} />
      <meshLineMaterial attach="material" transparent color={props.color} lineWidth={1} depthTest={false} />
    </mesh>
  );
};

const Segments = props => {
  return (
    <lineSegments position={props.position}>
      <geometry attach="geometry" vertices={props.points} />
      <lineBasicMaterial attach="material" color={props.color} />
    </lineSegments>
  );
};
