import React from 'react';

//

const Main = props => {
  const {children} = props;
  return (
    <div className="bg-base-100 pb-[16rem]">
      <div className="-z-10 h-16 w-full bg-primary" />
      <div className="-mt-12">{children}</div>
    </div>
  );
};

export default Main;
