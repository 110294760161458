import React from 'react';
import {Square} from 'lucide-react';
import {ResponsiveContainer, PieChart, Pie, Cell, Sector, Tooltip} from 'recharts';

import {
  checkEmpty,
  mapRange,
  shadeColor,
  sum,
  percentage,
  getQuarterIdFromQuarter,
  getRootTransactions,
  getTransactionsWithEntity,
  getEntityHierarchy,
  getElementsFromQuarterId,
  filterElements,
  getElementsByKey,
  getOrderFromClientByGroup,
  sortElementsBySpecificOrder,
} from './helpers';
import Module from './Module';
import TreeMenu from './TreeMenu';
import SectionDetails from './SectionDetails';

//

class ModuleBreakdown extends React.Component {
  constructor(props) {
    super(props);
    this.groups = [
      // {name: 'Type', key: 'type'},
      {name: 'Catégorie', key: 'parent_category'},
      {name: 'Sous-catégorie', key: 'sub_category'},
    ];
    this.state = {entity: null, group: 'parent_category', details: false};
    this.changeGroup = this.changeGroup.bind(this);
    this.entity = this.entity.bind(this);
    this.details = this.details.bind(this);
  }

  entity(id) {
    this.setState({entity: id, details: false});
  }

  changeGroup(e) {
    this.setState({details: false});
    this.setState({group: e.target.value});
  }

  details(i) {
    this.setState({details: i !== this.state.details ? i : false});
  }

  render() {
    const {client, transactions, quarterCurrent, quarterTarget} = this.props;

    // https://stackoverflow.com/a/154068/7662622
    const title = client.breakdown || 'Répartition';

    // get transactions
    const transactionsCurrent = getTransactionsWithEntity(getElementsFromQuarterId(transactions, getQuarterIdFromQuarter(quarterCurrent)));
    const transactionsCurrentEmpty = typeof transactionsCurrent !== 'undefined' ? checkEmpty(transactionsCurrent.transactions) : true;

    // render component only if current quarter has transactions associate to it
    if (transactionsCurrentEmpty) return null;

    const hierarchy = getEntityHierarchy(transactionsCurrent.transactions);
    const menu = <TreeMenu hierarchy={hierarchy} select={this.entity} />;

    const operations = filterElements(transactionsCurrent.transactions, n =>
      this.state.entity ? n.operation_entity_id.toString() === this.state.entity : n.operation_entity.ancestry === null
    );

    const entityTotal = sum(operations, true);

    let transactionsCurrentGroups = getElementsByKey(operations, this.state.group);
    // sort groups by custom order
    const order = getOrderFromClientByGroup(client, this.state.group);
    transactionsCurrentGroups = sortElementsBySpecificOrder(transactionsCurrentGroups, order);
    const groupsDatas = transactionsCurrentGroups.map((group, i) => {
      const s = sum(group.elements, true);
      const p = percentage(s, entityTotal);
      const c = shadeColor('#163B82', mapRange(p, 0, 100, 100, 0));
      return {
        name: group.key,
        value: p,
        color: c,
      };
    });

    let details;
    const transactionsCurrentGroup = transactionsCurrentGroups[this.state.details];
    const transactionsTarget = quarterTarget && getRootTransactions(getElementsFromQuarterId(operations, getQuarterIdFromQuarter(quarterTarget)));
    // && this.state.details
    if (this.state.details !== false) {
      details = (
        <SectionDetails
          client={client}
          quarterCurrent={quarterCurrent}
          quarterTarget={quarterTarget}
          transactionsCurrentGroup={transactionsCurrentGroup}
          transactionsTarget={transactionsTarget}
        />
      );
    }

    // prepare chart
    // http://recharts.org/en-US/api/Pie
    // http://recharts.org/en-US/api/Cell
    // http://recharts.org/en-US/api/Sector
    // http://recharts.org/en-US/api/Tooltip
    // http://recharts.org/en-US/api/ResponsiveContainer
    // https://jsfiddle.net/alidingling/hqnrgxpj/
    // https://jsfiddle.net/alidingling/vxq4ep63/
    const renderActive = props => {
      const {cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload} = props;
      return (
        <g>
          <text className="text-sm" x={cx} y={cy + outerRadius} dy="2em" fontStyle="italic" textAnchor="middle" fill="#B4B4B4">
            {payload.name}
          </text>
          <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill} />
          <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 5} outerRadius={outerRadius + 6} fill="#B4B4B4" />
        </g>
      );
    };
    const chartClick = (_, index) => {
      this.details(index);
    };
    const CustomTooltip = o => {
      if (o.active) {
        const group = o.payload[0].payload.payload;
        return (
          <div className="flex flex-nowrap rounded border bg-slate-50 p-2 text-sm font-light shadow-md">
            <Square size={16} strokeWidth={1} color="white" fill={group.color} className="mr-2 mt-0.5" />
            <div>
              <p className="mr-2">{group.name}</p>
              <p>{group.value}&#8239;%</p>
            </div>
          </div>
        );
      }
      return null;
    };

    return (
      <Module>
        <div className="flex flex-none basis-full flex-wrap overflow-hidden lg:basis-2/3">
          <div className="basis-full bg-neutral py-4 min-[480px]:basis-1/2">
            <h3 className="mb-4 h-8 px-4 font-bold leading-8 text-primary">{title}</h3>
            {menu}
          </div>

          <div className="basis-full py-4 min-[480px]:basis-1/2 min-[480px]:py-16">
            <div className="">
              <select value={this.state.group} onChange={e => this.changeGroup(e)} className="select select-bordered select-xs mx-4 my-2">
                {this.groups.map(group => (
                  <option key={group.key} value={group.key}>
                    {group.name}
                  </option>
                ))}
              </select>
            </div>
            <ul className="menu menu-horizontal menu-sm px-4 min-[480px]:menu-vertical">
              {groupsDatas.map((group, i) => {
                return (
                  <li key={i} className={`${i === this.state.details ? 'disabled' : ''}`}>
                    <button onClick={() => this.details(i)} className={`${i === this.state.details ? 'bg-neutral text-primary hover:bg-neutral-200' : ''}`}>
                      <span>
                        <Square size={16} strokeWidth={1} color="white" fill={group.color} className="mr-2" />
                      </span>
                      <span>{group.name}</span>
                      <div>{group.value}&#8239;%</div>
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        <div className="flex-none basis-full overflow-hidden border-t lg:basis-1/3 lg:border-none">
          <div className="min-h-80 h-full w-full p-4">
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  activeIndex={this.state.details === false ? null : this.state.details}
                  activeShape={renderActive}
                  data={groupsDatas}
                  innerRadius={75}
                  outerRadius={95}
                  paddingAngle={3}
                  dataKey="value"
                  onClick={chartClick}
                >
                  {groupsDatas.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} stroke={entry.color} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>

        {details}
      </Module>
    );
  }
}

export default ModuleBreakdown;
