import React from 'react';
import {render} from 'react-dom';
import {combineReducers, legacy_createStore as createStore, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';

import './index.css';
import {checkSome, getQuarterIdFromQuarter, getQuarterFromQuarterId, sortQuarters} from './helpers';
import {performRequest} from './fetch.js';
import App from './App';

//

// main
// https://reactjs.org/blog/2017/09/26/react-v16.0.html
// https://github.com/facebookincubator/create-react-app/blob/master/packages/react-scripts/template/README.md
// https://softwareengineering.stackexchange.com/a/297797
// TODO: I18N, Localization

const title = 'F&P System';
document.title = `${title} | Dashboard`;

// reducers
// note: quarters are stored by year, hence the 'years' state object
// https://redux.js.org/docs/basics/Reducers.html
// https://redux.js.org/docs/recipes/StructuringReducers.html
// https://redux.js.org/docs/recipes/reducers/InitializingState.html
// TODO: encapsulate reducers -> https://egghead.io/lessons/javascript-redux-colocating-selectors-with-reducers
// https://medium.com/@mikeric/simple-and-secure-auth-using-jwt-and-redux-134e0dd3c0b4

// on page refresh, and if a token is in localstorage :
// skip rendering login screen; if token is not valid logout action will trigger
const initialAuth = (token => ({
  status: token ? 'authenticated' : 'login',
  currentUser: null,
  errorMessage: null,
}))(localStorage.getItem('authToken'));

const auth = (state = initialAuth, action) => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return {
        ...state,
        status: 'login',
        errorMessage: null,
      };
    case 'LOGIN_CONFIRM':
      return {
        ...state,
        status: 'confirming',
        currentUser: action.user,
        errorMessage: null,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        status: 'authenticated',
        currentUser: null,
        errorMessage: null,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        status: 'login',
        currentUser: null,
        errorMessage: action.errorMessage,
      };
    case 'LOGOUT':
      return {
        ...state,
        status: 'login',
        currentUser: null,
        errorMessage: null,
      };
    default:
      return state;
  }
};

//

const initialOtp = {
  fetching: false,
  errorMessage: null,
};

const otp = (state = initialOtp, action) => {
  switch (action.type) {
    case 'OTP_REQUEST':
      return {
        ...state,
        fetching: true,
        errorMessage: null,
      };
    case 'OTP_REQUEST_SUCCESS':
      return {
        fetching: false,
        errorMessage: null,
      };
    case 'OTP_REQUEST_FAILURE':
      return {
        ...state,
        fetching: false,
        errorMessage: action.errorMessage,
      };
    case 'OTP_CONFIRM_REQUEST':
      return {
        ...state,
        errorMessage: null,
      };
    case 'OTP_CONFIRM_SUCCESS':
      return {
        errorMessage: null,
      };
    case 'OTP_CONFIRM_FAILURE':
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
};

//

const initialClient = {
  fetching: false,
  client: {},
};

// Fetching user information and testing token validity
const client = (state = initialClient, action) => {
  switch (action.type) {
    case 'CLIENT_FETCH_REQUEST':
      return {
        ...state,
        fetching: true,
      };
    case 'CLIENT_FETCH_SUCCESS':
      return {
        fetching: false,
        client: action.user,
      };
    default:
      return state;
  }
};

const initialQuarter = {
  fetching: false,
  quarters: [],
  current: {},
  target: {},
};

const quarters = (state = initialQuarter, action) => {
  switch (action.type) {
    case 'QUARTERS_FETCH_REQUEST':
      return {
        ...state,
        fetching: true,
        quarters: [],
        current: {},
      };
    case 'QUARTERS_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        quarters: action.quarters,
        current: action.current,
      };
    case 'QUARTERS_SELECT_CURRENT':
      return {
        ...state,
        current: action.current,
      };
    case 'QUARTERS_SELECT_TARGET':
      return {
        ...state,
        target: action.target,
      };
    case 'QUARTERS_FETCH_FAILURE':
      return state;
    default:
      return state;
  }
};

const initialTransactions = {
  fetching: false,
  transactions: [],
};

const transactions = (state = initialTransactions, action) => {
  switch (action.type) {
    case 'TRANSACTIONS_FETCH_REQUEST':
      return {
        ...state,
        fetching: true,
      };
    case 'TRANSACTIONS_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        transactions: [...state.transactions, action.transactions],
      };
    case 'TRANSACTIONS_FETCH_FAILURE':
      return state;
    default:
      return state;
  }
};

const initialReturns = {
  fetching: false,
  returns: [],
};

const returns = (state = initialReturns, action) => {
  switch (action.type) {
    case 'RETURNS_FETCH_REQUEST':
      return {
        ...state,
        fetching: true,
      };
    case 'RETURNS_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        returns: [...state.returns, action.returns],
      };
    case 'RETURNS_FETCH_FAILURE':
      return state;
    default:
      return state;
  }
};

const initialProperties = {
  fetching: false,
  properties: [],
};

const properties = (state = initialProperties, action) => {
  switch (action.type) {
    case 'PROPERTIES_FETCH_REQUEST':
      return {
        ...state,
        fetching: true,
      };
    case 'PROPERTIES_FETCH_SUCCESS':
      return {
        ...state,
        fetching: false,
        properties: action.properties,
      };
    case 'PROPERTIES_FETCH_FAILURE':
      return state;
    default:
      return state;
  }
};

const getTransactions = state => {
  return state.transactions.transactions;
};

const getReturns = state => {
  return state.returns.returns;
};

const appReducer = combineReducers({
  auth,
  otp,
  client,
  quarters,
  transactions,
  returns,
  properties,
});

// root reducer
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const state = (state, action) => {
  // delete token and restore client reducer
  if (action.type === 'LOGOUT') {
    localStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

// action creators
// https://redux.js.org/docs/basics/Actions.html
// https://egghead.io/lessons/javascript-redux-wrapping-dispatch-to-recognize-promises

const loginRequest = () => {
  return {type: 'LOGIN_REQUEST'};
};
const loginConfirm = user => {
  return {type: 'LOGIN_CONFIRM', user};
};
const loginFailure = errorMessage => {
  return {type: 'LOGIN_FAILURE', errorMessage};
};
const loginSuccess = () => {
  return {type: 'LOGIN_SUCCESS'};
};
export const logout = () => {
  return {type: 'LOGOUT'};
};

const otpRequest = () => {
  return {type: 'OTP_REQUEST'};
};
const otpRequestFailure = errorMessage => {
  return {type: 'OTP_REQUEST_FAILURE', errorMessage};
};
const otpRequestSuccess = () => {
  return {type: 'OTP_REQUEST_SUCCESS'};
};
const otpConfirmRequest = () => {
  return {type: 'OTP_CONFIRM_REQUEST'};
};
const otpConfirmFailure = errorMessage => {
  return {type: 'OTP_CONFIRM_FAILURE', errorMessage};
};
const otpConfirmSuccess = () => {
  return {type: 'OTP_CONFIRM_SUCCESS'};
};

const clientRequest = () => {
  return {type: 'CLIENT_FETCH_REQUEST'};
};
const clientSuccess = user => {
  return {type: 'CLIENT_FETCH_SUCCESS', user};
};

const quartersRequest = () => {
  return {type: 'QUARTERS_FETCH_REQUEST'};
};
const quartersSuccess = (quarters, current) => {
  return {type: 'QUARTERS_FETCH_SUCCESS', quarters, current};
};
const quartersFailure = () => {
  return {type: 'QUARTERS_FETCH_FAILURE'};
};
export const quartersSelectCurrent = current => {
  return {type: 'QUARTERS_SELECT_CURRENT', current};
};
export const quartersSelectTarget = target => {
  return {type: 'QUARTERS_SELECT_TARGET', target};
};

const transactionsRequest = () => {
  return {type: 'TRANSACTIONS_FETCH_REQUEST'};
};
const transactionsSuccess = transactions => {
  return {type: 'TRANSACTIONS_FETCH_SUCCESS', transactions};
};
const transactionsFailure = () => {
  return {type: 'TRANSACTIONS_FETCH_FAILURE'};
};

const returnsRequest = () => {
  return {type: 'RETURNS_FETCH_REQUEST'};
};
const returnsSuccess = returns => {
  return {type: 'RETURNS_FETCH_SUCCESS', returns};
};
const returnsFailure = () => {
  return {type: 'RETURNS_FETCH_FAILURE'};
};

const propertiesRequest = () => {
  return {type: 'PROPERTIES_FETCH_REQUEST'};
};
const propertiesSuccess = properties => {
  return {type: 'PROPERTIES_FETCH_SUCCESS', properties};
};
const propertiesFailure = () => {
  return {type: 'PROPERTIES_FETCH_FAILURE'};
};

// async actions
// happens between the action & reducer calls, async calls...
// https://redux.js.org/docs/advanced/
// https://stackoverflow.com/questions/35411423/how-to-dispatch-a-redux-action-with-a-timeout/35415559#35415559
// https://github.com/gaearon/redux-thunk#motivation
// https://egghead.io/lessons/javascript-redux-dispatching-actions-asynchronously-with-thunks
// https://egghead.io/lessons/javascript-redux-avoiding-race-conditions-with-thunks
// https://egghead.io/lessons/javascript-redux-colocating-selectors-with-reducers
// https://stackoverflow.com/a/35674575/7662622

export const authFetch = credentials => {
  return dispatch => {
    dispatch(loginRequest());
    performRequest('POST', 'client_auth', {auth: credentials}, false)
      .then(response => {
        dispatch(loginConfirm(credentials));
        return dispatch(otpFetch());
      })
      .catch(error => {
        return dispatch(loginFailure(error.response));
      });
  };
};
// 4: call to the client_token endpoint
const authConfirm = () => {
  return dispatch => {
    performRequest('POST', 'client_token', {auth: store.getState().auth.currentUser}, false)
      .then(response => {
        // save token in localStorage
        localStorage.setItem('authToken', response.data.jwt);
        localStorage.setItem('time', new Date());
        return dispatch(clientFetch());
      })
      .catch(error => {
        return dispatch(loginFailure(error.response));
      });
  };
};

// 2: call to the otp_auth endpoint
export const otpFetch = () => {
  return dispatch => {
    dispatch(otpRequest());

    // skip otp auth in development
    if (process.env.NODE_ENV === 'development') return;

    const user = store.getState().auth.currentUser;

    performRequest('POST', `otp_auth?email=${user.email}`, null, false)
      .then(response => {
        if (response.data.code === 'ACCESS_CHALLENGE') {
          return dispatch(otpRequestSuccess());
        } else {
          return dispatch(otpRequestFailure(response.data['reply-message']));
        }
      })
      .catch(error => {
        return dispatch(otpRequestFailure(error.response));
      });
  };
};
// 3: call to the auth endpoint
export const otpCheck = otp => {
  return (dispatch, getState) => {
    dispatch(otpConfirmRequest());

    // skip otp auth in development
    if (process.env.NODE_ENV === 'development') return dispatch(authConfirm());

    if (!otp.otpCode.trim()) {
      return dispatch(otpConfirmFailure('OTP cannot be empty'));
    }

    const user = store.getState().auth.currentUser;

    if (!otp.otpCode.trim()) {
      return dispatch(otpConfirmFailure('OTP cannot be empty'));
    }

    performRequest('POST', `auth?email=${user.email}&otp=${otp.otpCode}`, null, false)
      .then(response => {
        if (response.data.code === 'ACCESS_ALLOWED') {
          dispatch(otpConfirmSuccess(response.data));
          return dispatch(authConfirm());
        } else {
          return dispatch(otpConfirmFailure(response.data['reply-message']));
        }
      })
      .catch(error => {
        dispatch(otpConfirmFailure(error.response));
        return dispatch(logout());
      });
  };
};

export const clientFetch = () => {
  return dispatch => {
    dispatch(clientRequest());

    // get client's quarters if slug detected in pathname and current client is admin
    let client_slug = '';
    const slug = window.location.href.match(/([^/]*)\/*$/)[1];

    if (slug) client_slug = `?client=${slug}`;

    performRequest('GET', `client_info${client_slug}`, null, true)
      .then(response => {
        dispatch(loginSuccess());
        dispatch(clientSuccess(response.data));
        return dispatch(quartersFetch(response.data.slug));
      })
      .catch(error => dispatch(logout()));
  };
};

export const quartersFetch = user_slug => {
  return (dispatch, getState) => {
    dispatch(quartersRequest());

    performRequest('GET', `clients/${user_slug}/quarters`, null, true)
      .then(response => {
        const quarters = sortQuarters(response.data);

        // get the last quarter of the last year as the current one, target remains empty until selection
        let current;
        if (quarters.length) {
          const y = quarters[0];
          const q = y.quarters[y.quarters.length - 1];
          current = {year: y.year, quarter: q.quarter};
        } else {
          current = {};
        }

        return dispatch(quartersSuccess(quarters, current));
      })
      .then(quarters => {
        if (quarters.quarters.length) {
          const quarter = getQuarterFromQuarterId(quarters.quarters, quarters.current);
          dispatch(transactionsFetch(quarter));
          dispatch(returnsFetch(quarter));
        }
      })
      .catch(error => {
        return dispatch(quartersFailure());
      });
  };
};

export const transactionsFetch = quarter => {
  return (dispatch, getState) => {
    // resolve if quarters transactions exists already
    const transactionsState = getTransactions(getState());
    const exists = checkSome(transactionsState, ['quarter', getQuarterIdFromQuarter(quarter)]);
    if (exists) return Promise.resolve();

    dispatch(transactionsRequest());

    performRequest('GET', `quarters/${quarter.quarter.id}/operations`, null, true)
      .then(response => {
        const transactions = {
          quarter: getQuarterIdFromQuarter(quarter),
          transactions: response.data,
        };
        return dispatch(transactionsSuccess(transactions));
      })
      .catch(error => {
        return dispatch(transactionsFailure());
      });
  };
};

export const returnsFetch = quarter => {
  return (dispatch, getState) => {
    // resolve if quarters transactions exists already
    const returnsState = getReturns(getState());
    const exists = checkSome(returnsState, ['quarter', getQuarterIdFromQuarter(quarter)]);
    if (exists) return Promise.resolve();

    dispatch(returnsRequest());

    // https://github.com/reactjs/redux/issues/1676
    return dispatch(returnsFetchValues(quarter.quarter.id)).then(returnsValues => {
      return dispatch(returnsFetchTexts(quarter.quarter.id)).then(returnsTexts => {
        const returns = {
          quarter: getQuarterIdFromQuarter(quarter),
          returns: returnsValues,
          details: returnsTexts,
        };
        return dispatch(returnsSuccess(returns));
      });
    });
  };
};
const returnsFetchValues = id => {
  return dispatch => {
    return performRequest('GET', `quarters/${id}/return_operations?detail=false`, null, true).then(
      response => {
        return response.data;
      },
      error => {
        dispatch(returnsFailure());
        throw error;
      }
    );
  };
};
const returnsFetchTexts = id => {
  return dispatch => {
    return performRequest('GET', `quarters/${id}/return_operations?detail=true`, null, true).then(
      response => {
        return response.data;
      },
      error => {
        dispatch(returnsFailure());
        throw error;
      }
    );
  };
};

export const propertiesFetch = () => {
  return (dispatch, getState) => {
    dispatch(propertiesRequest());

    performRequest('GET', `property_management_reports`, null, true)
      .then(response => {
        return dispatch(propertiesSuccess(response.data));
      })
      .catch(error => {
        return dispatch(propertiesFailure());
      });
  };
};

//

// store
// https://redux.js.org/docs/basics/Store.html
// https://redux.js.org/docs/api/createStore.html
// redux devtools -> https://github.com/zalmoxisus/redux-devtools-extension#usage
const middlewares = [thunkMiddleware];
export const store = createStore(state, composeWithDevTools(applyMiddleware(...middlewares)));

// if token exists on refresh, attempt user fetch
if (localStorage.getItem('authToken')) {
  store.dispatch(clientFetch());
}

// redux store timeout
// adapted from https://embed.plnkr.co/plunk/JkW5ns
document.addEventListener('DOMContentLoaded', event => {
  setInterval(() => {
    const time = localStorage.getItem('time');
    if (time) {
      const elapsed = new Date().getTime() - new Date(time).getTime();
      const expiration = 24 * 60 * 60 * 1000;
      if (elapsed > expiration) {
        store.dispatch(logout());
      }
    }
  }, 3000);
});

//

// https://reactjs.org/docs/context.html
// https://github.com/reactjs/react-redux/blob/master/docs/api.md#provider-store
render(
  <Provider store={store}>
    <App title={title} />
  </Provider>,
  document.getElementById('root')
);
