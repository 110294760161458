import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {ArrowRight, ArrowLeft} from 'lucide-react';

import {quartersSelectCurrent, quartersSelectTarget, transactionsFetch, returnsFetch, propertiesFetch} from './index';
import {checkEmpty} from './helpers';
import Loading from './Loading';
import Head from './Head';
import Logout from './Logout';
import Landing from './Landing';
import Login from './Login';
import Otp from './Otp';
import Nav from './Nav';
import Dashboard from './Dashboard';

//

// https://reactjs.org/docs/components-and-props.html#functional-and-class-components
// https://medium.com/@npverni/how-to-declare-react-components-in-2017-2a90d9f7984c

const App = props => {
  const {
    title,
    status,
    client,
    quarters,
    quartersCurrent,
    quartersTarget,
    quartersFetching,
    transactions,
    transactionsFetching,
    returns,
    returnsFetching,
    properties,
    propertiesFetching,
    quartersSelectCurrent,
    quartersSelectTarget,
    transactionsFetch,
    returnsFetch,
    propertiesFetch,
  } = props;

  const [landing, setLanding] = useState(true);

  let page;
  let confirming;
  let nav;
  let dashboard;

  if (status === 'login') {
    if (landing) page = <Landing />;
    else page = <Login />;
  } else if (status === 'confirming') {
    if (landing) confirming = <Landing />;
    else confirming = <Otp />;
  } else if (status === 'authenticated') {
    if (quartersFetching) {
      nav = <Loading />;
    } else {
      if (quarters.length) {
        nav = (
          <div className="top-[-1px] z-10 w-full md:sticky">
            <Nav
              quarters={quarters}
              currentQuarter={quartersCurrent}
              targetQuarter={quartersTarget}
              selectCurrentQuarter={quartersSelectCurrent}
              selectTargetQuarter={quartersSelectTarget}
              fetchTransactions={transactionsFetch}
              fetchReturns={returnsFetch}
            />
          </div>
        );

        dashboard = (
          <Dashboard
            client={client}
            quarters={quarters}
            currentQuarter={quartersCurrent}
            targetQuarter={quartersTarget}
            transactions={transactions}
            transactionsFetching={transactionsFetching}
            returns={returns}
            returnsFetching={returnsFetching}
            properties={properties}
            propertiesFetching={propertiesFetching}
            propertiesFetch={propertiesFetch}
          />
        );
      } else {
        nav = (
          <div className="flex w-full justify-center bg-primary p-4">
            <div className="max-w-6xl flex-grow text-primary-content">Aucun trimestre</div>
          </div>
        );
      }
    }
  }

  // collapsing header
  let head;
  let button;
  if (landing)
    button = (
      <button onClick={() => setLanding(false)} className="btn btn-sm">
        Se connecter
        <ArrowRight size={16} />
      </button>
    );
  else
    button = (
      <button onClick={() => setLanding(true)} className="btn btn-sm">
        Retour
        <ArrowLeft size={16} />
      </button>
    );
  const sign = checkEmpty(client) ? button : <Logout />;
  head = (
    <Head title={title} filled={!checkEmpty(client)}>
      {sign}
    </Head>
  );

  return (
    <div className="w-[100vw] min-w-[320px] overflow-y-auto overflow-x-hidden min-[320px]:h-[100vh]">
      {head}
      {page}
      {confirming}
      {nav}
      {dashboard}
    </div>
  );
};

// https://stackoverflow.com/a/43415107/7662622;
const mapStateToProps = state => ({
  status: state.auth.status,
  client: state.client.client,
  quarters: state.quarters.quarters,
  quartersCurrent: state.quarters.current,
  quartersTarget: state.quarters.target,
  quartersFetching: state.quarters.fetching,
  transactions: state.transactions.transactions,
  transactionsFetching: state.transactions.fetching,
  returns: state.returns.returns,
  returnsFetching: state.returns.fetching,
  properties: state.properties.properties,
  propertiesFetching: state.properties.fetching,
});

const mapDispatchToProps = dispatch => ({
  quartersSelectCurrent: current => dispatch(quartersSelectCurrent(current)),
  quartersSelectTarget: target => dispatch(quartersSelectTarget(target)),
  transactionsFetch: quarter => dispatch(transactionsFetch(quarter)),
  returnsFetch: quarter => dispatch(returnsFetch(quarter)),
  propertiesFetch: () => dispatch(propertiesFetch()),
});

// https://github.com/reactjs/react-redux/blob/master/docs/api.md#connectmapstatetoprops-mapdispatchtoprops-mergeprops-options
// https://gist.github.com/gaearon/1d19088790e70ac32ea636c025ba424e
export default connect(mapStateToProps, mapDispatchToProps)(App);
