import React from 'react';
import {connect} from 'react-redux';
import {AlertCircle} from 'lucide-react';

import {otpFetch, otpCheck} from './index';

//

class Otp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {otpCode: ''};
    this.onSubmit = this.onSubmit.bind(this);
    this.onClick = this.onClick.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    this.focus();
  }

  render() {
    const {otpCode} = this.state;

    let error;
    if (this.props.errorMessage) {
      error = (
        <div className="alert alert-warning mb-3">
          <AlertCircle size={16} />
          <span>OTP invalide</span>
        </div>
      );
    }

    return (
      <div className="flex w-full items-center justify-center px-4 py-28">
        <form name="Otp_check" onSubmit={this.onSubmit} className="card w-96 bg-base-100 shadow-xl">
          <div className="card-body">
            {error}
            <label className="mb-1">Code OTP</label>
            <input
              onChange={e => this.setState({otpCode: e.target.value})}
              type="password"
              name="otp"
              value={otpCode}
              ref={input => {
                this.otpInput = input;
              }}
              className="input input-bordered w-full"
            />
            <input type="submit" value="Confirmer OTP" className="btn mt-3" />
            <button onClick={this.onClick} className="btn btn-ghost btn-xs self-center">
              Renvoyer le code par SMS
            </button>
          </div>
        </form>
      </div>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.otpCheck({otpCode: this.state.otpCode});
  }

  onClick(e) {
    e.preventDefault();
    this.props.otpFetch();
    this.focus();
  }

  focus() {
    this.otpInput.focus();
  }
}

const mapStateToProps = state => ({
  errorMessage: state.otp.errorMessage,
});

const mapDispatchToProps = (dispatch, state) => ({
  otpCheck: otp => dispatch(otpCheck(otp)),
  otpFetch: () => dispatch(otpFetch()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Otp);
