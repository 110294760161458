import React from 'react';
import {ArrowRight, ArrowUpRight, ArrowDownRight} from 'lucide-react';

import {getRate} from './helpers';

//

const Variation = props => {
  const dif = getRate(props.value);
  const rat = dif.rate;
  const val = dif.value;
  let indicator;
  if (rat === 'equ') indicator = <ArrowRight size={16} color="steelblue" />;
  if (rat === 'pos') indicator = <ArrowUpRight size={16} color="limegreen" />;
  if (rat === 'neg') indicator = <ArrowDownRight size={16} color="tomato" />;
  let value;
  if (props.type === 'currency') {
    value = <span className="inline-block align-middle">{val.toLocaleString('de-CH')}</span>;
  } else if (props.type === 'percents') {
    value = (
      <span>
        {val}
        <span className="inline-block align-middle">&#8239;%</span>
      </span>
    );
  }
  return (
    <span title={props.title} className="badge badge-sm">
      <span className="inline-block align-middle">{indicator}</span>
      {value}
    </span>
  );
};

Variation.defaultProps = {type: 'currency'};

export default Variation;
