import React from 'react';

import {checkEmpty, checkEqual, checkSome, getQuarterFromQuarterId, getQuartersFromYear} from './helpers';
import DropdownYears from './DropdownYears';
import DropdownTargets from './DropdownTargets';

//

const Nav = props => {
  const {quarters, currentQuarter, targetQuarter, selectCurrentQuarter, selectTargetQuarter, fetchTransactions, fetchReturns} = props;

  const onYearClick = y => {
    const year = getQuartersFromYear(quarters, y);

    // send change quarter action if year is different from the selected one
    if (y !== currentQuarter.year) {
      const next = {
        year: y,
        quarter: year.quarters[year.quarters.length - 1].quarter,
      };
      // unselect target quarter if newly select current quarter if the same as the target quarter
      if (checkEqual(next, targetQuarter)) selectTargetQuarter({});
      selectCurrentQuarter(next);
      const quarterNext = getQuarterFromQuarterId(quarters, next);
      fetchTransactions(quarterNext);
      fetchReturns(quarterNext);
    }
  };

  const onCurrentClick = q => {
    const quarter = getQuarterFromQuarterId(quarters, currentQuarter);

    // send change quarter action if quarter exists & is different from the selected one
    if (checkSome(quarter.year.quarters, {quarter: q}) && q !== currentQuarter.quarter) {
      const next = {year: currentQuarter.year, quarter: q};
      // unselect target quarter if newly select current quarter if the same as the target quarter
      if (checkEqual(next, targetQuarter)) selectTargetQuarter({});
      selectCurrentQuarter(next);
      const quarterNext = getQuarterFromQuarterId(quarters, next);
      fetchTransactions(quarterNext);
      fetchReturns(quarterNext);
    }
  };

  const onTargetClick = q => {
    // set targetQuarter null if not null and nullable field is selected
    if (!checkEmpty(targetQuarter) && checkEmpty(q)) {
      selectTargetQuarter({});
      // send change quarter action if quarter exists & is different from the selected one
    } else {
      if (!(q.year === currentQuarter.year && q.quarter === currentQuarter.quarter) && !(q.year === targetQuarter.year && q.quarter === targetQuarter.quarter)) {
        const next = {year: q.year, quarter: q.quarter};
        selectTargetQuarter(next);
        const quarterNext = getQuarterFromQuarterId(quarters, next);
        fetchTransactions(quarterNext);
        fetchReturns(quarterNext);
      }
    }
  };

  const year = getQuartersFromYear(quarters, currentQuarter.year);

  return (
    <div className="flex w-full justify-center bg-primary px-4">
      <nav className="flex max-w-6xl flex-grow flex-wrap justify-between gap-x-4">
        <div className="rounded-box my-2 bg-base-100 p-2 shadow-xl">
          <DropdownYears quarters={quarters} currentQuarter={currentQuarter} onYearClick={onYearClick} />

          {[
            {label: 'T1', period: 'janvier-mars'},
            {label: 'T2', period: 'avril-juin'},
            {label: 'T3', period: 'juillet-septembre'},
            {label: 'T4', period: 'octobre-décembre'},
          ].map((q, i) => {
            const exists = checkSome(year.quarters, {quarter: i + 1});
            return (
              <button
                key={i}
                disabled={!exists}
                onClick={() => onCurrentClick(i + 1)}
                className={`btn btn-sm mr-2 font-normal normal-case ${exists && currentQuarter.quarter === i + 1 ? 'bg-secondary text-primary' : ''}`}
              >
                <span>{q.label}</span>
                <span className="hidden min-[960px]:block">{q.period}</span>
              </button>
            );
          })}
        </div>

        <div className="rounded-box my-2 bg-base-100 p-2 shadow-xl">
          <DropdownTargets quarters={quarters} currentQuarter={currentQuarter} targetQuarter={targetQuarter} onTargetClick={onTargetClick} />
        </div>
      </nav>
    </div>
  );
};

export default Nav;
