import React from 'react';
import {ChevronRight} from 'lucide-react';

import {findElement, filterElements, mapElements} from './helpers';

//

class TreeMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {breadcrumb: [], nodes: []};
  }

  componentDidMount() {
    this.node('0');
  }

  breadcrumb = id => {
    const {hierarchy} = this.props;
    const parent = (n, a) => {
      const node = findElement(hierarchy, ['id', n]);
      if (node) return [...a, node.id, ...parent(node.parent, a)];
      return [...a, n];
    };
    return parent(id, []).reverse();
  };

  node = id => {
    const {hierarchy, select} = this.props;
    const breadcrumb = this.breadcrumb(id);
    const nodes = filterElements(hierarchy, n => n.parent === id.toString());

    this.setState({breadcrumb, nodes});
    select(id === '0' ? null : id);
  };

  leaf = id => {
    const {select} = this.props;
    const breadcrumb = this.breadcrumb(id);

    this.setState({breadcrumb});
    select(id);
  };

  render() {
    const {hierarchy} = this.props;

    const breadcrumb = this.state.breadcrumb;
    const selected = breadcrumb[breadcrumb.length - 1];
    const path = mapElements(breadcrumb, (v, i) => {
      const node = findElement(hierarchy, ['id', v]);
      const name = v === '0' ? 'Entités' : node.name;
      const active = v === selected;
      const click = () => this.node(v);
      return (
        <li key={i}>
          <button
            onClick={click}
            disabled={active}
            className={`btn btn-link btn-xs normal-case no-underline hover:text-primary focus:text-primary focus:underline focus:outline-none ${
              active ? 'disabled:bg-secondary disabled:text-primary' : 'text-inherit'
            }`}
          >
            {name}
          </button>
        </li>
      );
    });

    const list = this.state.nodes.map(n => {
      const active = n.id === selected;
      const isParent = n.children.length ? true : false;
      const click = () => (isParent ? this.node(n.id) : this.leaf(n.id));
      const arrow = isParent && <ChevronRight size={16} />;
      return (
        <li key={n.id} className={`${active ? 'disabled' : ''}`}>
          <button onClick={click} className={`justify-between ${active ? 'bg-primary text-neutral' : ''}`}>
            {n.name}
            {arrow}
          </button>
        </li>
      );
    });

    return (
      <>
        <div className="breadcrumbs mx-4 text-sm">
          <ul>{path}</ul>
        </div>
        <ul className="menu menu-horizontal menu-sm px-4 min-[480px]:menu-vertical">{list}</ul>
      </>
    );
  }
}

export default TreeMenu;
