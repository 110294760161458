import React from 'react';

import {getQuarterNameFromQuarter, getTransactionFromTransaction, getOrderFromClientByGroup, sortElementsBySpecificOrder} from './helpers';
import Table from './Table';
import Variation from './Variation';

//

class SectionDetails extends React.Component {
  render() {
    const {client, quarterCurrent, quarterTarget, transactionsCurrentGroup, transactionsTarget, query} = this.props;

    const columns = [
      {cell: 'Nom', accessor: 'name'},
      {cell: 'Type', accessor: 'type'},
      {cell: 'Mandataire', accessor: 'mandatary'},
      {cell: 'Géo', accessor: 'location'},
      {cell: 'Monnaie', accessor: 'currency'},
      {cell: getQuarterNameFromQuarter(quarterCurrent), accessor: 'value', styles: 'text-right'},
    ];

    if (transactionsTarget) {
      const h = `Variation ${getQuarterNameFromQuarter(quarterTarget)}`;
      columns.push({cell: h, accessor: 'variation', styles: 'text-right'});
    }

    let datas = [];
    // sort details table elements by type order
    const typesOrder = getOrderFromClientByGroup(client, 'type');
    const elements = sortElementsBySpecificOrder(transactionsCurrentGroup.elements, typesOrder, 'type');

    for (let i = 0; i < elements.length; i++) {
      const transactionCurrent = elements[i];
      let data = {
        name: transactionCurrent.name,
        type: transactionCurrent.operation_type.name,
        mandatary: transactionCurrent.operation_mandatary.name,
        location: transactionCurrent.operation_location.name,
        currency: transactionCurrent.operation_currency.name,
        value: transactionCurrent.value.toLocaleString('de-CH'),
      };
      if (transactionsTarget) {
        // get corresponding target transaction
        const transactionTarget = getTransactionFromTransaction(transactionsTarget.transactions, transactionCurrent);
        if (transactionTarget !== undefined) {
          data.variation = (
            <Variation
              value={transactionCurrent.value - transactionTarget.value}
              title={`${getQuarterNameFromQuarter(quarterTarget)} - ${transactionTarget.value.toLocaleString('de-CH')}`}
            />
          );
        } else {
          data.variation = '-';
        }
      }
      datas.push(data);
    }

    // filter datas from searchfield query
    if (query) {
      datas = datas.filter(data => {
        // search in all columns
        const searched = [data.name, data.type, data.mandatary, data.location, data.currency].join(' ').toLowerCase();
        return searched.indexOf(query) !== -1;
      });
    }

    return (
      <div className="flex-none basis-full overflow-hidden bg-neutral">
        <Table columns={columns} datas={datas} />
      </div>
    );
  }
}

export default SectionDetails;
