import React from 'react';
import ReactSwipe from 'react-swipe';
// import {ResponsiveContainer, LineChart, Line, Tooltip} from 'recharts';
import {Infinity, ChevronLeft, ChevronRight} from 'lucide-react';

//

class SectionOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {current: 0};
    this.transition = this.transition.bind(this);
  }

  componentDidUpdate(prevProps) {
    const n = this.props.datas.length - 1;
    if (n !== prevProps.datas.length - 1) {
      this.setState({current: n});
    }
  }

  transition() {
    this.setState({current: this.reactSwipe.getPos()});
  }

  render() {
    const {datas} = this.props;

    // <ResponsiveContainer width="100%" height="100%">
    //   <LineChart data={datas}>
    //     <Tooltip />
    //     <Line
    //       type="monotone"
    //       dataKey="total"
    //       strokeWidth={2}
    //       stroke="#82ca9d"
    //     />
    //   </LineChart>
    // </ResponsiveContainer>

    let markup;
    if (datas.length) {
      markup = (
        <>
          <ReactSwipe
            key={datas.length}
            ref={reactSwipe => (this.reactSwipe = reactSwipe)}
            swipeOptions={{
              startSlide: datas.length - 1,
              continuous: false,
              transitionEnd: this.transition,
            }}
            className="flex-grow select-none [&>div]:h-full"
          >
            {datas.map((data, i) => (
              <div key={i} className="flex h-full items-center justify-center p-4">
                <div className="pb-4 pr-8">
                  <p className="text-primary">{data.year}</p>
                  <p className="text-2xl font-bold text-primary">{data.total.toLocaleString('de-CH')}</p>
                </div>
              </div>
            ))}
          </ReactSwipe>
          <div className="flex justify-center px-4 pb-4">
            <button
              onClick={() => this.reactSwipe.prev()}
              className="btn btn-square btn-ghost btn-sm mx-2 text-primary focus:bg-slate-300 focus:outline-none"
              disabled={datas.length <= 1}
            >
              <ChevronLeft />
            </button>
            <button
              onClick={() => this.reactSwipe.next()}
              className="btn btn-square btn-ghost btn-sm mx-2 text-primary focus:bg-slate-300 focus:outline-none"
              disabled={datas.length <= 1}
            >
              <ChevronRight />
            </button>
          </div>
        </>
      );
    } else {
      markup = (
        <div className="flex flex-grow items-center justify-center pb-8">
          <div className="tooltip tooltip-info" data-tip="Visiter d'autres années pour afficher">
            <Infinity className="text-primary" />
          </div>
        </div>
      );
    }

    return (
      <div className="flex h-full flex-col pt-4">
        <h3 className="mb-4 h-8 px-4 font-bold leading-8 text-primary">Aperçu</h3>
        {markup}
      </div>
    );
  }
}

export default SectionOverview;
