import React from 'react';
import {Mail, Phone, Minus} from 'lucide-react';

//

const SectionTitle = ({name, text, contact, email, phone}) => {
  return (
    <div className="flex h-full flex-col">
      {name && <h3 className="mb-4 h-8 px-4 font-bold leading-8 text-primary">{name}</h3>}
      <div className="mb-4 flex-grow px-4">
        <p className="text-xs uppercase text-primary">Info</p>
        <p className="text-sm text-slate-400">{text || '—'}</p>
      </div>
      {contact && (
        <div className="mb-4 px-4">
          <p className="text-xs uppercase text-primary">Contact</p>
          <p className="text-sm text-slate-400">{contact}</p>
          {email && (
            <a role="button" href={`mailto:${email}`} title={email} className="btn btn-square btn-neutral btn-sm mr-2 mt-2 text-primary focus:bg-slate-300">
              <Mail size={16} />
            </a>
          )}
          {phone && (
            <a role="button" href={`tel:${phone}`} title={phone} className="btn btn-square btn-neutral btn-sm mr-2 mt-2 text-primary focus:bg-slate-300">
              <Phone size={16} />
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default SectionTitle;
