import React from 'react';

import {checkEmpty, sum, getQuarterFromQuarterId, getRootTransactions, getElementsFromQuarterId} from './helpers';
import Main from './Main';
import ModuleIntro from './ModuleIntro';
import ModuleGeneral from './ModuleGeneral';
import ModuleBreakdown from './ModuleBreakdown';
import ModuleAnalysis from './ModuleAnalysis';
import ModuleProperty from './ModuleProperty';

//

class Dashboard extends React.Component {
  render() {
    const {client, quarters, currentQuarter, targetQuarter, transactions, returns, returnsFetching, properties, propertiesFetching, propertiesFetch} = this.props;

    // get current and target quarters (target can be empty)
    const quarterCurrent = getQuarterFromQuarterId(quarters, currentQuarter);

    // check if a comparison target quarter is selected
    const compare = !checkEmpty(targetQuarter);
    const quarterTarget = compare && getQuarterFromQuarterId(quarters, targetQuarter);

    // get totals (last quarter of each year, except current)
    const date = new Date();
    const now = String(date.getFullYear());
    const datas = quarters
      .reduce((acc, val) => {
        if (val.year === now) return acc;
        const quarterId = {
          year: val.year,
          quarter: val.quarters[val.quarters.length - 1].quarter,
        };
        const transactionsCurrent = getRootTransactions(getElementsFromQuarterId(transactions, quarterId));
        if (typeof transactionsCurrent !== 'undefined') return [...acc, {year: val.year, total: sum(transactionsCurrent.transactions)}];
        return acc;
      }, [])
      .reverse();

    const p1 = {
      s1: {
        name: client.name,
        text: client.notes,
        contact: client.contact_name,
        email: client.contact_email,
        phone: client.contact_phone,
      },
      s2: {
        currency: client.currency,
        transactions,
        quarterCurrent,
        quarterTarget,
      },
      s3: {
        datas,
      },
    };
    const p2 = {
      client,
      transactions,
      quarterCurrent,
      quarterTarget,
    };
    const p3 = {
      client,
      returns,
      returnsFetching,
      quarterCurrent,
      quarterTarget,
    };
    const p4 = {
      client,
      properties,
      propertiesFetching,
      propertiesFetch,
      quarterCurrent,
    };

    return (
      <Main>
        <ModuleIntro {...p1} />
        <ModuleGeneral {...p2} />
        <ModuleBreakdown {...p2} />
        <ModuleAnalysis {...p3} />
        <ModuleProperty {...p4} />
      </Main>
    );
  }
}

export default Dashboard;
