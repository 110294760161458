import React, {useEffect, useState} from 'react';

import Module from './Module';

//

const ModuleProperty = props => {
  const {client, properties, propertiesFetching, propertiesFetch, quarterCurrent} = props;
  const [reports, setReports] = useState([]);

  useEffect(() => {
    propertiesFetch();
  }, []);

  useEffect(() => {
    if (!quarterCurrent || !properties) return;

    const year = quarterCurrent.year.year;
    const current = properties.reduce((acc, curr) => (curr.year.toString() === year ? [...acc, curr] : acc), []);
    const sorted = current.sort((a, b) => a.property.address.localeCompare(b.property.address));
    const computed = sorted.map(report => {
      const property = report.property.address;
      const name = report.property.company.name;
      const city = report.property.city;
      const acquisition_price = parseFloat(report.property.acquisition_price);
      const mortgage_debt = parseFloat(report.property.mortgage_debt);
      const rental_status = parseFloat(report.rental_status);
      const annual_rents = parseFloat(report.annual_rents);
      const unpaid_rents = parseFloat(report.unpaid_rents);
      const market_vacancies = parseFloat(report.market_vacancies);
      const works_vacancies = parseFloat(report.works_vacancies);
      const running_expenses = parseFloat(report.running_expenses);
      const management_fees = parseFloat(report.management_fees);
      const building_maintenance = parseFloat(report.building_maintenance);
      const major_building_works = parseFloat(report.major_building_works);
      const apartment_maintenance = parseFloat(report.apartment_maintenance);
      const major_apartment_works = parseFloat(report.major_apartment_works);
      const mortgage_interest = parseFloat(report.mortgage_interest);
      const mortgage_depreciation = parseFloat(report.mortgage_depreciation);

      // dynamic values
      const vacancies_total = market_vacancies + works_vacancies;
      const rental_status_vacancies_ratio = market_vacancies / rental_status;
      const rents_unpaid_ratio = unpaid_rents / annual_rents;
      const expenses_total = running_expenses + management_fees + building_maintenance + major_building_works + apartment_maintenance + major_apartment_works;
      const expenses_rents_ratio = expenses_total / annual_rents;
      const expenses_rents_ratio_light = (expenses_total - major_apartment_works) / annual_rents;
      const liquidity_after_interest_and_depreciation = annual_rents - expenses_total - mortgage_interest - mortgage_depreciation;
      const gross_returns = annual_rents / acquisition_price;
      const net_returns = liquidity_after_interest_and_depreciation / acquisition_price;

      // values tree
      return {
        label: property,
        items: [
          {label: 'Détention', value: name},
          {label: 'Localité', value: city},
          {label: "Prix d'acquisition", value: acquisition_price.toLocaleString('de-CH')},
          {label: 'Dette hypothécaire', value: mortgage_debt.toLocaleString('de-CH')},
          {
            label: 'Revenus locatifs',
            items: [
              {label: 'État locatif au 31 décembre', value: rental_status.toLocaleString('de-CH')},
              {label: 'Loyers annuels effectivement encaissés', value: annual_rents.toLocaleString('de-CH')},
              {label: 'Loyers impayés', value: unpaid_rents.toLocaleString('de-CH')},
              {label: 'Vacants "marché"', value: market_vacancies.toLocaleString('de-CH')},
              {label: 'Vacants "travaux"', value: works_vacancies.toLocaleString('de-CH')},
              {label: 'Vacants totaux', value: vacancies_total.toLocaleString('de-CH')},
              {label: 'Ratio vacants sur état locatif', value: `${rental_status_vacancies_ratio.toLocaleString('de-CH')} %`},
              {label: 'Ratio loyers impayés sur loyers encaissés', value: `${rents_unpaid_ratio.toLocaleString('de-CH')} %`},
            ],
          },
          {
            label: "Charges d'exploitation courantes",
            items: [
              {label: 'Charges courantes', value: running_expenses.toLocaleString('de-CH')},
              {label: 'Honoraires de gérance', value: management_fees.toLocaleString('de-CH')},
              {label: "Entretien courant de l'immeuble", value: building_maintenance.toLocaleString('de-CH')},
              {label: "Travaux lourds de l'immeuble", value: major_building_works.toLocaleString('de-CH')},
              {label: 'Entretien courant des appartements', value: apartment_maintenance.toLocaleString('de-CH')},
              {label: 'Travaux lourds appartements', value: major_apartment_works.toLocaleString('de-CH')},
              {label: "Total charges d'exploitation", value: expenses_total.toLocaleString('de-CH')},
              {label: "Ratio charges d'exploitation sur loyers encaissés", value: `${expenses_rents_ratio.toLocaleString('de-CH')} %`},
              {label: "Ratio charges d'exploitation sur loyers encaissés (hors travaux lourds)", value: `${expenses_rents_ratio_light.toLocaleString('de-CH')} %`},
            ],
          },
          {
            label: 'Charges financières',
            items: [
              {label: 'Intérêts hypothécaires', value: mortgage_interest.toLocaleString('de-CH')},
              {label: 'Amortissements hypothécaires', value: mortgage_depreciation.toLocaleString('de-CH')},
            ],
          },
          {
            label: 'Balance des liquidités',
            items: [{label: 'Liquidités après intérêts et amortissements', value: liquidity_after_interest_and_depreciation.toLocaleString('de-CH')}],
          },
          {
            label: 'Rendements',
            items: [
              {label: 'Rendement brut', value: `${gross_returns.toLocaleString('de-CH')} %`},
              {label: 'Rendement net', value: `${net_returns.toLocaleString('de-CH')} %`},
            ],
          },
        ],
      };
    });

    setReports(computed);
  }, [quarterCurrent, properties]);

  if (reports.length === 0) return null;

  const title = client.company || 'Immobilier';

  return (
    <Module>
      <div className="flex-none basis-full overflow-hidden">
        <h3 className="m-4 h-8 font-bold leading-8 text-primary">{title}</h3>
      </div>

      <div className="m-4 w-full">
        {reports.map((node, index) => (
          <NestedCollapse key={index} level={0} label={node.label} items={node.items} />
        ))}
      </div>
    </Module>
  );
};

export default ModuleProperty;

const NestedCollapse = ({level, label, items}) => {
  if (level === 0) {
    return (
      <div tabIndex={0} className="collapse collapse-plus mb-2 border">
        <input type="radio" name={`accordion-${level}`} />
        <div className="collapse-title font-medium text-primary">{label}</div>
        <div className="collapse-content p-0">
          {items.map((node, index) =>
            node.hasOwnProperty('items') ? (
              <NestedCollapse key={index} level={level + 1} label={node.label} items={node.items} />
            ) : (
              <div key={index} className="flex items-end justify-between gap-8 border-b border-gray-100 px-4 py-2">
                <span className="text-sm">{node.label}</span>
                <span className="text-sm">{node.value}</span>
              </div>
            )
          )}
        </div>
      </div>
    );
  }

  return (
    <div tabIndex={0} className="collapse collapse-arrow">
      <input type="checkbox" defaultChecked />
      <div className="collapse-title text-primary">{label}</div>
      <div className="collapse-content p-0">
        {items.map((node, index) =>
          node.hasOwnProperty('items') ? (
            <NestedCollapse key={index} level={level + 1} label={node.label} items={node.items} />
          ) : (
            <div key={index} className="flex items-end justify-between gap-8 border-b border-gray-100 px-4 py-2">
              <span className="text-sm">{node.label}</span>
              <span className="text-sm">{node.value}</span>
            </div>
          )
        )}
      </div>
    </div>
  );
};
