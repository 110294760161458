import React from 'react';

//

class Table extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {columns, datas} = this.props;

    if (datas.length === 0) return <p className="p-4 text-sm font-light">Aucun résultat.</p>;

    return (
      <div className="overflow-x-auto border-t bg-white pb-4">
        <table className="table table-zebra">
          <thead>
            <tr className="text-primary">
              {columns.map((column, i) => (
                <th key={i} className={`${column.styles ? column.styles : ''}`}>
                  {column.cell}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {datas.map((data, i) => (
              <tr key={i}>
                {columns.map((column, j) => (
                  <td key={`${i}-${j}`} className={`${column.styles ? column.styles : ''}`}>
                    {data[column.accessor]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Table;
