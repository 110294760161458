import React from 'react';
import {connect} from 'react-redux';
import {AlertCircle} from 'lucide-react';

import {authFetch} from './index';

//

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {email: '', password: ''};
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    const {email, password} = this.state;

    let error;
    if (this.props.errorMessage) {
      error = (
        <div className="alert alert-warning mb-3">
          <AlertCircle />
          <span>Identifiants invalides</span>
        </div>
      );
    }

    return (
      <div className="flex w-full items-center justify-center px-4 py-28">
        <form name="Login" onSubmit={this.onSubmit} className="card w-96 bg-base-100 shadow-xl">
          <div className="card-body">
            {error}
            <label className="mb-1">Adresse Email</label>
            <input type="email" name="email" onChange={e => this.setState({email: e.target.value})} value={email} className="input input-bordered w-full" />
            <label className="mb-1">Mot de passe</label>
            <input onChange={e => this.setState({password: e.target.value})} type="password" name="password" value={password} className="input input-bordered w-full" />
            <input type="submit" value="Se connecter" className="btn mt-3" />
          </div>
        </form>
      </div>
    );
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.login({email: this.state.email, password: this.state.password});
  }
}

const mapStateToProps = state => ({
  errorMessage: state.auth.errorMessage,
});

const mapDispatchToProps = dispatch => ({
  login: credentials => dispatch(authFetch(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
