import React from 'react';
import {connect} from 'react-redux';
import {LogOut} from 'lucide-react';

import {logout} from './index';

//

const Logout = ({onClick}) => (
  <button onClick={onClick} className="btn btn-ghost btn-sm text-white">
    <LogOut size={16} />
  </button>
);

//

const mapDispatchToProps = dispatch => ({
  onClick: () => dispatch(logout()),
});

export default connect(false, mapDispatchToProps)(Logout);
