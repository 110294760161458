import React from 'react';

import Module from './Module';
import SectionTitle from './SectionTitle';
import SectionFortune from './SectionFortune';
import SectionOverview from './SectionOverview';

//

const ModuleIntro = props => {
  return (
    <Module>
      <div className="flex flex-none basis-full flex-wrap overflow-hidden md:basis-2/3">
        <div className="basis-full py-4 max-[480px]:border-b min-[480px]:basis-1/2">
          <SectionTitle {...props.s1} />
        </div>
        <div className="basis-full py-4 min-[480px]:basis-1/2">
          <SectionFortune {...props.s2} />
        </div>
      </div>
      <div className="flex-none basis-full overflow-hidden bg-neutral md:basis-1/3">
        <SectionOverview {...props.s3} />
      </div>
    </Module>
  );
};

export default ModuleIntro;
