import React from 'react';

//

class DropdownYears extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(y) {
    this.props.onYearClick(y);
  }

  render() {
    const years = this.props.quarters.map(year => {
      const active = year.year === this.props.currentQuarter.year;
      return (
        <button
          key={year.year}
          onClick={() => this.onClick(year.year)}
          disabled={active}
          className={`btn btn-sm m-1 font-normal normal-case ${active ? 'bg-secondary text-primary disabled:bg-secondary disabled:text-primary' : ''}`}
        >
          {year.year}
        </button>
      );
    });

    return (
      <div className="dropdown mr-2">
        <label tabIndex={0} className="btn btn-sm font-normal normal-case">
          {this.props.currentQuarter.year}
        </label>
        <div tabIndex={0} className="menu dropdown-content rounded-box z-[1] mt-4 bg-base-100 p-1 shadow">
          {years}
        </div>
      </div>
    );
  }
}

export default DropdownYears;
