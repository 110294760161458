import React from 'react';

import {checkEmpty} from './helpers';

//

const DropdownTargets = props => {
  const onClick = q => {
    props.onTargetClick(q);
  };

  const targets = [];

  targets.push(
    <div key="none" className="flex">
      <button key="none" disabled={checkEmpty(props.targetQuarter)} onClick={() => onClick({})} className="btn btn-sm m-1 flex-grow font-normal normal-case">
        Aucun
      </button>
    </div>
  );

  for (let i = 0; i < props.quarters.length; i++) {
    const year = props.quarters[i];

    const quarters = [];
    for (let j = year.quarters.length - 1; j >= 0; j--) {
      const quarter = year.quarters[j];

      // check button status
      const bt = props.targetQuarter.year === year.year && props.targetQuarter.quarter === quarter.quarter;
      const bc = props.currentQuarter.year === year.year && props.currentQuarter.quarter === quarter.quarter;

      quarters.push(
        <button
          key={j}
          disabled={bt || bc}
          onClick={() => onClick({year: year.year, quarter: quarter.quarter})}
          className={`btn btn-sm m-1 flex-grow font-normal normal-case ${bt ? 'bg-secondary text-primary disabled:bg-secondary disabled:text-primary' : ''}`}
        >
          {`T${quarter.quarter}`}
        </button>
      );
    }

    targets.push(
      <div key={i} className="collapse collapse-arrow">
        <input type="checkbox" className="peer" />
        <div className="collapse-title">
          <span className="text-sm font-normal normal-case">{year.year}</span>
        </div>
        <div className="collapse-content flex !p-0">{quarters}</div>
      </div>
    );
  }

  return (
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="btn btn-sm font-normal normal-case">
        {checkEmpty(props.targetQuarter) ? 'Comparer' : `${props.targetQuarter.year} - T${props.targetQuarter.quarter}`}
      </label>
      <div tabIndex={0} className="dropdown-content rounded-box mt-4 bg-base-100 p-1 shadow">
        {targets}
      </div>
    </div>
  );
};

export default DropdownTargets;
