import axios from 'axios';

// API fetching operations

// Generic axios request
export const performRequest = (method, url, params, auth) => {
  const body = method === 'get' ? 'params' : 'data';

  const config = {
    method,
    url,
    baseURL: process.env.REACT_APP_API_URL,
    [body]: params || {},
  };
  if (auth) {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem('authToken')}`,
    };
  }

  return axios.request(config);
};
