import React, {useState} from 'react';

import {
  checkEmpty,
  sum,
  growth,
  getQuarterIdFromQuarter,
  getQuarterNameFromQuarter,
  getElementsFromQuarterId,
  sortElements,
  getElementsByKey,
  getElementsFromKey,
  getOrderFromClientByGroup,
  sortElementsBySpecificOrder,
} from './helpers';
import Module from './Module';
import Variation from './Variation';

//

const ModuleAnalysis = props => {
  const {client, returns, quarterCurrent, quarterTarget} = props;
  const [tab, setTab] = useState(0);
  const [all, setAll] = useState(false);

  const clickTab = t => {
    if (t !== tab) setTab(t);
  };

  const toggleAll = t => {
    setAll(!all);
  };

  const title = client.analysis || 'Analyse';

  let content;

  const returnsCurrent = getElementsFromQuarterId(returns, getQuarterIdFromQuarter(quarterCurrent));
  const returnsCurrentEmpty = typeof returnsCurrent === 'undefined' ? true : checkEmpty(returnsCurrent.returns);

  if (returnsCurrentEmpty) return null;

  // TODO: reverse order to match file order ?
  let returnsCurrentGroups = getElementsByKey(returnsCurrent.returns, 'parent_category');

  // sort groups by custom order
  const order = getOrderFromClientByGroup(client, 'returns_parent_category');
  returnsCurrentGroups = sortElementsBySpecificOrder(returnsCurrentGroups, order);

  const returnsTarget = quarterTarget && getElementsFromQuarterId(returns, getQuarterIdFromQuarter(quarterTarget));
  let returnsTargetGroups = false;
  let targetName;
  if (returnsTarget) {
    returnsTargetGroups = getElementsByKey(returnsTarget.returns, 'parent_category');
    targetName = getQuarterNameFromQuarter(quarterTarget);
  }

  const infos = [];

  for (let i = 0; i < returnsCurrentGroups.length; i++) {
    const returnsCurrentGroup = returnsCurrentGroups[i];
    const returnsCurrentGroupName = returnsCurrentGroup.key;
    const returnsCurrentGroupTotal = sum(returnsCurrentGroup.elements);
    let returnsCurrentGroupDetails = getElementsByKey(returnsCurrentGroup.elements, 'sub_category');
    // sort groups by custom order
    const order = getOrderFromClientByGroup(client, 'returns_sub_category');
    returnsCurrentGroupDetails = sortElementsBySpecificOrder(returnsCurrentGroupDetails, order);

    const returnsCurrentGroupTexts = getElementsFromKey(getElementsByKey(returnsCurrent.details, 'parent_category'), returnsCurrentGroup.key);
    let returnsCurrentGroupTextsDetails = returnsCurrentGroupTexts && getElementsByKey(returnsCurrentGroupTexts.elements, 'sub_category');
    if (returnsCurrentGroupTextsDetails !== undefined) {
      // sort groups by custom order
      const order = getOrderFromClientByGroup(client, 'returns_sub_category');
      returnsCurrentGroupTextsDetails = sortElementsBySpecificOrder(returnsCurrentGroupTextsDetails, order);
    }

    let target = {};
    if (returnsTarget) {
      const returnsTargetGroup = getElementsFromKey(returnsTargetGroups, returnsCurrentGroup.key);
      if (returnsTargetGroup !== undefined) {
        const returnsTargetGroupTotal = sum(returnsTargetGroup.elements);
        const returnsTargetGroupDetails = getElementsByKey(returnsTargetGroup.elements, 'sub_category');
        target = {
          total: returnsTargetGroupTotal,
          details: returnsTargetGroupDetails,
        };
      }
    }

    infos.push({
      name: returnsCurrentGroupName,
      total: returnsCurrentGroupTotal,
      details: returnsCurrentGroupDetails,
      texts: returnsCurrentGroupTextsDetails,
      target,
    });
  }

  // jsx

  let tabs;

  // select tab markup
  tabs = [];
  for (let i = 0; i < infos.length; i++) {
    tabs.push(
      <li key={i} className={`md:border-b md:py-5 ${i === tab ? 'disabled' : ''}`}>
        <button onClick={() => clickTab(i)} className={`text-sm ${i === tab ? 'font-bold text-primary' : ''}`}>
          {infos[i].name}
        </button>
      </li>
    );
  }
  const details = <Details current={infos[tab].details} target={infos[tab].target} targetName={targetName} />;
  const infosTexts = <InfosTexts texts={infos[tab].texts} />;
  const content1 = (
    <div className="flex flex-none basis-full flex-wrap overflow-hidden">
      <div className="flex-none basis-full overflow-hidden px-2 pt-2 md:basis-1/3 md:pb-28 lg:basis-2/6">
        <ul className="menu menu-horizontal md:menu-vertical">{tabs}</ul>
      </div>

      <div className="flex flex-none basis-full items-start overflow-hidden md:basis-2/3 lg:basis-4/6">
        <div className="flex flex-none basis-full flex-wrap overflow-hidden md:pt-14">
          <div className="flex-none basis-full overflow-hidden lg:basis-1/2">{infosTexts}</div>

          <div className="flex-none basis-full overflow-hidden py-4 lg:basis-1/2">
            <div className="px-4">
              <p className="border-b px-4 text-sm font-light text-neutral-400">Total</p>
            </div>
            {details}
          </div>
        </div>
      </div>
    </div>
  );

  // all tabs markup
  tabs = [];
  for (let i = 0; i < infos.length; i++) {
    const details = <Details current={infos[i].details} target={infos[i].target} targetName={targetName} />;
    const infosTexts = <InfosTexts texts={infos[i].texts} />;

    tabs.push(
      <div className="flex-none basis-full overflow-hidden md:flex" key={i}>
        <div className="flex-none basis-full overflow-hidden md:basis-1/3 lg:basis-2/6">
          <p className="border-b px-4 pt-4 text-sm font-bold text-primary">{infos[i].name}</p>
        </div>
        <div className="flex flex-none basis-full items-start overflow-hidden md:basis-2/3 lg:basis-4/6">
          <div className="flex flex-none basis-full flex-wrap overflow-hidden">
            <div className="flex-none basis-full overflow-hidden lg:basis-1/2">{infosTexts}</div>

            <div className="flex-none basis-full overflow-hidden py-4 lg:basis-1/2">
              <div className="px-4">
                <p className="border-b px-4 text-sm font-light text-neutral-400">Total</p>
              </div>
              {details}
            </div>
          </div>
        </div>
      </div>
    );
  }
  const content2 = <div className="flex flex-none basis-full flex-wrap overflow-hidden">{tabs}</div>;

  // content
  content = all ? content2 : content1;

  return (
    <Module>
      <div className="flex flex-grow overflow-hidden">
        <h3 className="m-4 h-8 font-bold leading-8 text-primary">{title}</h3>
      </div>
      <div className="flex overflow-hidden">
        <div className="m-4 flex h-8 justify-end">
          <label className="label cursor-pointer">
            <span className="label-text mr-2 text-primary">Afficher tout</span>
            <input type="checkbox" checked={all} onChange={toggleAll} className="toggle toggle-xs checked:toggle-primary" />
          </label>
        </div>
      </div>

      {content}
    </Module>
  );
};

export default ModuleAnalysis;

const Details = props => {
  const details = [];
  const currentDetails = props.current;
  const targetDetails = props.target;
  for (let i = 0; i < currentDetails.length; i++) {
    const currentDetail = currentDetails[i];
    const currentDetailTotal = sum(currentDetail.elements);

    let detailVariation;
    let targetDetail;
    if (!checkEmpty(targetDetails)) {
      targetDetail = getElementsFromKey(targetDetails.details, currentDetail.key);
      let targetDetailTotal;
      if (targetDetail !== undefined) {
        targetDetailTotal = sum(targetDetail.elements);
        detailVariation = <DetailsVariation current={currentDetailTotal} target={targetDetailTotal} name={props.targetName} />;
      }
    }

    const subdetails = [];
    let currentDetailsDetails = currentDetail.elements;
    // sort by size order (desc > reverse)
    currentDetailsDetails = sortElements(currentDetailsDetails, 'value').reverse();
    let targetDetailDetails;
    if (targetDetail) targetDetailDetails = targetDetail.elements;
    for (let j = 0; j < currentDetailsDetails.length; j++) {
      const currentDetailDetail = currentDetailsDetails[j];
      const currentDetailDetailTotal = currentDetailDetail.value;

      let detailDetailVariation;
      if (targetDetailDetails !== undefined) {
        const targetDetailDetail = getElementsFromKey(targetDetailDetails, currentDetailDetail.name, 'name');
        if (targetDetailDetail !== undefined) {
          const targetDetailDetailTotal = targetDetailDetail.value;
          detailDetailVariation = <DetailsVariation current={currentDetailDetailTotal} target={targetDetailDetailTotal} name={props.targetName} />;
        }
      }

      subdetails.push(
        <div key={j} className="flex flex-wrap px-4 py-2 text-sm">
          <div className="flex flex-none basis-1/2 items-center overflow-hidden">
            <span className="font-bold">{currentDetailDetail.value.toLocaleString('de-CH')}</span>
          </div>
          <div className="flex flex-none basis-1/2 items-center overflow-hidden">
            <span className="font-light">{currentDetailDetail.name}</span>
          </div>
          {detailDetailVariation}
        </div>
      );
    }

    details.push(
      <div key={i} className="collapse collapse-arrow px-4">
        <input type="checkbox" className="peer" />
        <div className="collapse-title flex flex-wrap items-center border-b py-7 text-sm">
          <div className="flex flex-none basis-1/2 items-center overflow-hidden">
            <span className="font-bold">{currentDetailTotal.toLocaleString('de-CH')}</span>
          </div>
          <div className="flex flex-none basis-1/2 items-center overflow-hidden">
            <span className="font-light">{currentDetail.key}</span>
          </div>
          {detailVariation}
        </div>
        <div className="collapse-content bg-neutral p-0 pr-8">{subdetails}</div>
      </div>
    );
  }

  return details;
};

const DetailsVariation = props => {
  const growthRate = growth(props.current, props.target);
  let variation;
  // if target value is zero & growth is infinity, display the current value in currency rather than percentage
  if (isNaN(growthRate)) {
    variation = <Variation value={props.current} />;
  } else {
    variation = <Variation value={growthRate} type="percents" />;
  }

  return (
    <div className="flex basis-full flex-wrap text-sm">
      <div className="flex-none basis-1/2 overflow-hidden">
        <span className="font-light text-neutral-400">{props.target.toLocaleString('de-CH')}</span>
      </div>
      <div className="flex-none basis-1/2 overflow-hidden">
        <span className="font-light text-neutral-400">{props.name}</span>
      </div>
      <div className="flex flex-none basis-1/2 items-center overflow-hidden">
        <Variation value={props.current - props.target} />
      </div>
      <div className="flex flex-none basis-1/2 items-center overflow-hidden">{variation}</div>
    </div>
  );
};

const InfosTexts = props => {
  const currentTexts = props.texts;

  if (currentTexts === undefined) return <div className="h-0 lg:h-[37px] lg:border-b" />;

  const texts = [];
  for (let i = 0; i < currentTexts.length; i++) {
    const currentTextDetail = currentTexts[i];
    const currentTextDetailDetails = currentTexts[i].elements;
    const subtexts = [];
    for (let j = 0; j < currentTextDetailDetails.length; j++) {
      const currentTextDetailDetail = currentTextDetailDetails[j];
      subtexts.push(
        <div key={j} className="flex px-8 py-2 text-sm">
          <div className="flex-grow">
            <p className="font-light">{currentTextDetailDetail.name}</p>
          </div>
          <div className="pl-4">
            <p className="font-bold">{currentTextDetailDetail.detail}</p>
          </div>
        </div>
      );
    }
    texts.push(
      <div key={i}>
        <div className="px-4">
          <p className="mb-3 border-b px-4 text-sm font-light text-neutral-400">{currentTextDetail.key}</p>
        </div>
        {subtexts}
      </div>
    );
  }

  return <div className="py-4">{texts}</div>;
};
