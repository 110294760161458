import find from 'lodash/find';
import sumBy from 'lodash/sumBy';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import indexOf from 'lodash/indexOf';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';

// selectors (specified)
// TODO: document app logic (minimum object passing, human readable formats)

export const sortQuarters = quarters => {
  const quarter = q => omit(q, ['user', 'year']);
  const year = (q, y) => ({year: y, quarters: map(q, quarter)});
  return orderBy(map(groupBy(orderBy(quarters, 'quarter'), 'year'), year), 'year', 'desc');
};

// build 'full' quarter object
export const getQuarterFromQuarterId = (quarters, quarterId) => {
  const yearSelected = find(quarters, ['year', quarterId.year]);
  const quarterSelected = find(yearSelected.quarters, ['quarter', quarterId.quarter]);
  return {year: yearSelected, quarter: quarterSelected};
};

// build 'id' quarter object
export const getQuarterIdFromQuarter = quarter => {
  return {year: quarter.year.year, quarter: quarter.quarter.quarter};
};

// get elements from 'id' quarter object
export const getElementsFromQuarterId = (arr, quarterId) => {
  const selected = find(arr, ['quarter', quarterId]);
  return selected;
};

export const getQuartersFromYear = (quarters, year) => {
  return find(quarters, ['year', year]);
};

export const getQuarterNameFromQuarter = quarter => {
  return `T${quarter.quarter.quarter} ${quarter.year.year}`;
};

export const getTransactionFromTransaction = (transactions, transaction) => {
  const selected = find(transactions, {
    name: transaction.name,
    type: transaction.type,
    parent_category: transaction.parent_category,
    sub_category: transaction.sub_category,
  });
  return selected;
};

export const getRootTransactions = transactions => {
  if (typeof transactions === 'undefined') return undefined;
  return {
    ...transactions,
    transactions: filter(transactions.transactions, o => typeof o.operation_entity === 'undefined' || (o.operation_entity && o.operation_entity.ancestry === null)),
  };
};

export const getTransactionsWithEntity = transactions => {
  if (typeof transactions === 'undefined') return undefined;
  const filtered = filter(transactions.transactions, o => !isNil(o.operation_entity));
  return {...transactions, transactions: filtered};
};

export const getEntityHierarchy = transactions => {
  // group entities by ancestry id to retrieve the hierarchy
  transactions = groupBy(transactions, o => (o.operation_entity.ancestry !== null ? o.operation_entity.ancestry.substr(o.operation_entity.ancestry.lastIndexOf('/') + 1) : '0'));
  // loop ancestry groups and retrieve entities infos
  const entities = reduce(
    transactions,
    (result, value, key) => {
      map(value, o => {
        const nil = isNil(find(result, ['id', o.operation_entity_id.toString()]));
        if (nil)
          result.push({
            id: o.operation_entity_id.toString(),
            name: o.operation_entity.name,
            parent: key,
            children: reduce(transactions[o.operation_entity_id], (a, v, k) => (!includes(a, v.operation_entity_id.toString()) ? [...a, v.operation_entity_id.toString()] : a), []),
          });
      });
      return result;
    },
    []
  );
  return entities;
};

// embeds the logic to extract groups (parent_category, sub_category, type) orders from the Client model
export const getOrderFromClientByGroup = (client, group) => {
  const category = c => c.name;
  let order = null;
  if (group === 'returns_parent_category') order = map(filter(client.return_categories_order, ['ancestry_depth', 0]), category);
  if (group === 'returns_sub_category') order = map(filter(client.return_categories_order, ['ancestry_depth', 1]), category);
  if (group === 'parent_category') order = map(filter(client.categories_order, ['ancestry_depth', 0]), category);
  if (group === 'sub_category') order = map(filter(client.categories_order, ['ancestry_depth', 1]), category);
  if (group === 'type') order = map(client.types_order, category);
  return order;
};

// assume 'order' to be a String array generated by getOrderFromClientByGroup()
export const sortElementsBySpecificOrder = (arr, order, key = 'key') => {
  // https://stackoverflow.com/a/18859288/7662622
  return sortBy(arr, o => {
    return indexOf(order, o[key]);
  });
};

// selectors (generalized)

export const findElement = (arr, fun) => {
  return find(arr, fun);
};

export const filterElements = (arr, fun) => {
  return filter(arr, fun);
};

export const mapElements = (arr, fun) => {
  return map(arr, fun);
};

export const sortElements = (arr, properties) => {
  return sortBy(arr, properties);
};

export const getElementsByKey = (arr, key) => {
  const group = (t, c) => ({key: c, elements: t});
  // const selected = map(groupBy(orderBy(arr, key), key), group)
  return map(groupBy(arr, key), group);
};

// without a 'prop' parameter, assume 'arr' was generated by getElementsByKey() (and thus has 'key' properties)
export const getElementsFromKey = (arr, key, prop = 'key') => {
  return find(arr, [prop, key]);
};

export const addOrRemove = (arr, val) => {
  // https://stackoverflow.com/a/7486130/7662622
  const mod = arr.slice();
  const index = mod.indexOf(val);
  if (index === -1) {
    mod.push(val);
  } else {
    mod.splice(index, 1);
  }
  return mod;
};

// calculators (specified)

// assume elements have a 'value' property to sum by
// TODO: check/convert currencies
export const sum = (arr, abs = false) => {
  return sumBy(arr, o => (abs ? Math.abs(o.value) : o.value));
};

export const percentage = (n, o) => {
  return round((n / o) * 100.0, 2);
};

export const growth = (n, o) => {
  if (n - o === 0) return 0;
  return round(((n - o) / o) * 100.0, 2);
};

// calculators (generalized)

export const checkEmpty = obj => {
  return isEmpty(obj);
};

export const checkSome = (obj, props) => {
  return some(obj, props);
};

export const checkEqual = (current, target) => {
  return isEqual(current, target);
};

export const mapRange = (value, low1, high1, low2, high2) => {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1);
};

// https://stackoverflow.com/a/13542669/7662622
export const shadeColor = (color, percent) => {
  const hex = color.slice(1);
  const num = parseInt(hex, 16);

  let r = (num >> 16) + percent;
  let g = ((num >> 8) & 0x00ff) + percent;
  let b = (num & 0x0000ff) + percent;

  r = Math.min(Math.max(r, 0), 255);
  g = Math.min(Math.max(g, 0), 255);
  b = Math.min(Math.max(b, 0), 255);

  const newColor = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;

  return newColor;
};

export const getRate = val => {
  const rate = val === 0 ? 'equ' : val > 0 ? 'pos' : 'neg';
  const value = Math.abs(val);

  return {rate, value};
};
